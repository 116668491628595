import $ from 'jquery';
import Carousel from 'bootstrap/js/src/carousel';
import Modals from '../components/modals';
import {lazyLoad} from '../helpers/lazyLoadHelpers';

class InfoPage {
	constructor() {
		this.init();
	}

	init() {
		this.initCarousel();
		this.rotateFAQArrow();
		new Modals();

		const mediaPartnersCarousel = document.getElementById('media_partners');

		if (mediaPartnersCarousel) {
			mediaPartnersCarousel.addEventListener('slide.bs.carousel', this.desktopSloganController);
		}
	}

	rotateFAQArrow() {
		$('h3.faqTitle').click(function() {
			$(this)
				.find('i')
				.toggleClass('rotate');
		});
	}

	loadCarouselImages = (carousel) => {
		lazyLoad({
			'elements_selector': `#${carousel.id} .carousel-item .lazy`
		}).loadAll();
	};

	initCarousel() {
		const carousels = document.querySelectorAll('.carousel');

		carousels.forEach((carousel) => {
			new Carousel(carousel, {
				'interval': 4000
			});

			carousel.addEventListener('touchend', () => {
				this.loadCarouselImages(carousel);
			});

			carousel.addEventListener('click', () => {
				this.loadCarouselImages(carousel);
			});
		});
	}

	desktopSloganController(ev) {
		$('.quotes .quote').each(function(index) {
			if (index === ev.to) {
				$(this).addClass('active');
			} else {
				$(this).removeClass('active');
			}
		});
	}

}

new InfoPage();